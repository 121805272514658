var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

/*
Turbolinks 5.2.0
Copyright © 2018 Basecamp, LLC
 */
(function () {
  var t = this || _global;
  (function () {
    (function () {
      (this || _global).Turbolinks = {
        supported: function () {
          return null != window.history.pushState && null != window.requestAnimationFrame && null != window.addEventListener;
        }(),
        visit: function (t, r) {
          return e.controller.visit(t, r);
        },
        clearCache: function () {
          return e.controller.clearCache();
        },
        setProgressBarDelay: function (t) {
          return e.controller.setProgressBarDelay(t);
        }
      };
    }).call(this || _global);
  }).call(t);
  var e = t.Turbolinks;
  (function () {
    (function () {
      var t,
          r,
          n,
          o = [].slice;
      e.copyObject = function (t) {
        var e, r, n;
        r = {};

        for (e in t) n = t[e], r[e] = n;

        return r;
      }, e.closest = function (e, r) {
        return t.call(e, r);
      }, t = function () {
        var t, e;
        return t = document.documentElement, null != (e = t.closest) ? e : function (t) {
          var e;

          for (e = this || _global; e;) {
            if (e.nodeType === Node.ELEMENT_NODE && r.call(e, t)) return e;
            e = e.parentNode;
          }
        };
      }(), e.defer = function (t) {
        return setTimeout(t, 1);
      }, e.throttle = function (t) {
        var e;
        return e = null, function () {
          var r;
          return r = 1 <= arguments.length ? o.call(arguments, 0) : [], null != e ? e : e = requestAnimationFrame(function (n) {
            return function () {
              return e = null, t.apply(n, r);
            };
          }(this || _global));
        };
      }, e.dispatch = function (t, e) {
        var r, o, i, s, a, u;
        return a = null != e ? e : {}, u = a.target, r = a.cancelable, o = a.data, i = document.createEvent("Events"), i.initEvent(t, !0, r === !0), i.data = null != o ? o : {}, i.cancelable && !n && (s = i.preventDefault, i.preventDefault = function () {
          return (this || _global).defaultPrevented || Object.defineProperty(this || _global, "defaultPrevented", {
            get: function () {
              return !0;
            }
          }), s.call(this || _global);
        }), (null != u ? u : document).dispatchEvent(i), i;
      }, n = function () {
        var t;
        return t = document.createEvent("Events"), t.initEvent("test", !0, !0), t.preventDefault(), t.defaultPrevented;
      }(), e.match = function (t, e) {
        return r.call(t, e);
      }, r = function () {
        var t, e, r, n;
        return t = document.documentElement, null != (e = null != (r = null != (n = t.matchesSelector) ? n : t.webkitMatchesSelector) ? r : t.msMatchesSelector) ? e : t.mozMatchesSelector;
      }(), e.uuid = function () {
        var t, e, r;

        for (r = "", t = e = 1; 36 >= e; t = ++e) r += 9 === t || 14 === t || 19 === t || 24 === t ? "-" : 15 === t ? "4" : 20 === t ? (Math.floor(4 * Math.random()) + 8).toString(16) : Math.floor(15 * Math.random()).toString(16);

        return r;
      };
    }).call(this || _global), function () {
      e.Location = function () {
        function t(t) {
          var e, r;
          null == t && (t = ""), r = document.createElement("a"), r.href = t.toString(), (this || _global).absoluteURL = r.href, e = r.hash.length, 2 > e ? (this || _global).requestURL = (this || _global).absoluteURL : ((this || _global).requestURL = (this || _global).absoluteURL.slice(0, -e), (this || _global).anchor = r.hash.slice(1));
        }

        var e, r, n, o;
        return t.wrap = function (t) {
          return t instanceof (this || _global) ? t : new (this || _global)(t);
        }, t.prototype.getOrigin = function () {
          return (this || _global).absoluteURL.split("/", 3).join("/");
        }, t.prototype.getPath = function () {
          var t, e;
          return null != (t = null != (e = (this || _global).requestURL.match(/\/\/[^\/]*(\/[^?;]*)/)) ? e[1] : void 0) ? t : "/";
        }, t.prototype.getPathComponents = function () {
          return this.getPath().split("/").slice(1);
        }, t.prototype.getLastPathComponent = function () {
          return this.getPathComponents().slice(-1)[0];
        }, t.prototype.getExtension = function () {
          var t, e;
          return null != (t = null != (e = this.getLastPathComponent().match(/\.[^.]*$/)) ? e[0] : void 0) ? t : "";
        }, t.prototype.isHTML = function () {
          return this.getExtension().match(/^(?:|\.(?:htm|html|xhtml))$/);
        }, t.prototype.isPrefixedBy = function (t) {
          var e;
          return e = r(t), this.isEqualTo(t) || o((this || _global).absoluteURL, e);
        }, t.prototype.isEqualTo = function (t) {
          return (this || _global).absoluteURL === (null != t ? t.absoluteURL : void 0);
        }, t.prototype.toCacheKey = function () {
          return (this || _global).requestURL;
        }, t.prototype.toJSON = function () {
          return (this || _global).absoluteURL;
        }, t.prototype.toString = function () {
          return (this || _global).absoluteURL;
        }, t.prototype.valueOf = function () {
          return (this || _global).absoluteURL;
        }, r = function (t) {
          return e(t.getOrigin() + t.getPath());
        }, e = function (t) {
          return n(t, "/") ? t : t + "/";
        }, o = function (t, e) {
          return t.slice(0, e.length) === e;
        }, n = function (t, e) {
          return t.slice(-e.length) === e;
        }, t;
      }();
    }.call(this || _global), function () {
      var t = function (t, e) {
        return function () {
          return t.apply(e, arguments);
        };
      };

      e.HttpRequest = function () {
        function r(r, n, o) {
          (this || _global).delegate = r, (this || _global).requestCanceled = t((this || _global).requestCanceled, this || _global), (this || _global).requestTimedOut = t((this || _global).requestTimedOut, this || _global), (this || _global).requestFailed = t((this || _global).requestFailed, this || _global), (this || _global).requestLoaded = t((this || _global).requestLoaded, this || _global), (this || _global).requestProgressed = t((this || _global).requestProgressed, this || _global), (this || _global).url = e.Location.wrap(n).requestURL, (this || _global).referrer = e.Location.wrap(o).absoluteURL, this.createXHR();
        }

        return r.NETWORK_FAILURE = 0, r.TIMEOUT_FAILURE = -1, r.timeout = 60, r.prototype.send = function () {
          var t;
          return (this || _global).xhr && !(this || _global).sent ? (this.notifyApplicationBeforeRequestStart(), this.setProgress(0), (this || _global).xhr.send(), (this || _global).sent = !0, "function" == typeof (t = (this || _global).delegate).requestStarted ? t.requestStarted() : void 0) : void 0;
        }, r.prototype.cancel = function () {
          return (this || _global).xhr && (this || _global).sent ? (this || _global).xhr.abort() : void 0;
        }, r.prototype.requestProgressed = function (t) {
          return t.lengthComputable ? this.setProgress(t.loaded / t.total) : void 0;
        }, r.prototype.requestLoaded = function () {
          return this.endRequest(function (t) {
            return function () {
              var e;
              return 200 <= (e = t.xhr.status) && 300 > e ? t.delegate.requestCompletedWithResponse(t.xhr.responseText, t.xhr.getResponseHeader("Turbolinks-Location")) : (t.failed = !0, t.delegate.requestFailedWithStatusCode(t.xhr.status, t.xhr.responseText));
            };
          }(this || _global));
        }, r.prototype.requestFailed = function () {
          return this.endRequest(function (t) {
            return function () {
              return t.failed = !0, t.delegate.requestFailedWithStatusCode(t.constructor.NETWORK_FAILURE);
            };
          }(this || _global));
        }, r.prototype.requestTimedOut = function () {
          return this.endRequest(function (t) {
            return function () {
              return t.failed = !0, t.delegate.requestFailedWithStatusCode(t.constructor.TIMEOUT_FAILURE);
            };
          }(this || _global));
        }, r.prototype.requestCanceled = function () {
          return this.endRequest();
        }, r.prototype.notifyApplicationBeforeRequestStart = function () {
          return e.dispatch("turbolinks:request-start", {
            data: {
              url: (this || _global).url,
              xhr: (this || _global).xhr
            }
          });
        }, r.prototype.notifyApplicationAfterRequestEnd = function () {
          return e.dispatch("turbolinks:request-end", {
            data: {
              url: (this || _global).url,
              xhr: (this || _global).xhr
            }
          });
        }, r.prototype.createXHR = function () {
          return (this || _global).xhr = new XMLHttpRequest(), (this || _global).xhr.open("GET", (this || _global).url, !0), (this || _global).xhr.timeout = 1000 * (this || _global).constructor.timeout, (this || _global).xhr.setRequestHeader("Accept", "text/html, application/xhtml+xml"), (this || _global).xhr.setRequestHeader("Turbolinks-Referrer", (this || _global).referrer), (this || _global).xhr.onprogress = (this || _global).requestProgressed, (this || _global).xhr.onload = (this || _global).requestLoaded, (this || _global).xhr.onerror = (this || _global).requestFailed, (this || _global).xhr.ontimeout = (this || _global).requestTimedOut, (this || _global).xhr.onabort = (this || _global).requestCanceled;
        }, r.prototype.endRequest = function (t) {
          return (this || _global).xhr ? (this.notifyApplicationAfterRequestEnd(), null != t && t.call(this || _global), this.destroy()) : void 0;
        }, r.prototype.setProgress = function (t) {
          var e;
          return (this || _global).progress = t, "function" == typeof (e = (this || _global).delegate).requestProgressed ? e.requestProgressed((this || _global).progress) : void 0;
        }, r.prototype.destroy = function () {
          var t;
          return this.setProgress(1), "function" == typeof (t = (this || _global).delegate).requestFinished && t.requestFinished(), (this || _global).delegate = null, (this || _global).xhr = null;
        }, r;
      }();
    }.call(this || _global), function () {
      var t = function (t, e) {
        return function () {
          return t.apply(e, arguments);
        };
      };

      e.ProgressBar = function () {
        function e() {
          (this || _global).trickle = t((this || _global).trickle, this || _global), (this || _global).stylesheetElement = this.createStylesheetElement(), (this || _global).progressElement = this.createProgressElement();
        }

        var r;
        return r = 300, e.defaultCSS = ".turbolinks-progress-bar {\n  position: fixed;\n  display: block;\n  top: 0;\n  left: 0;\n  height: 3px;\n  background: #0076ff;\n  z-index: 9999;\n  transition: width " + r + "ms ease-out, opacity " + r / 2 + "ms " + r / 2 + "ms ease-in;\n  transform: translate3d(0, 0, 0);\n}", e.prototype.show = function () {
          return (this || _global).visible ? void 0 : ((this || _global).visible = !0, this.installStylesheetElement(), this.installProgressElement(), this.startTrickling());
        }, e.prototype.hide = function () {
          return (this || _global).visible && !(this || _global).hiding ? ((this || _global).hiding = !0, this.fadeProgressElement(function (t) {
            return function () {
              return t.uninstallProgressElement(), t.stopTrickling(), t.visible = !1, t.hiding = !1;
            };
          }(this || _global))) : void 0;
        }, e.prototype.setValue = function (t) {
          return (this || _global).value = t, this.refresh();
        }, e.prototype.installStylesheetElement = function () {
          return document.head.insertBefore((this || _global).stylesheetElement, document.head.firstChild);
        }, e.prototype.installProgressElement = function () {
          return (this || _global).progressElement.style.width = 0, (this || _global).progressElement.style.opacity = 1, document.documentElement.insertBefore((this || _global).progressElement, document.body), this.refresh();
        }, e.prototype.fadeProgressElement = function (t) {
          return (this || _global).progressElement.style.opacity = 0, setTimeout(t, 1.5 * r);
        }, e.prototype.uninstallProgressElement = function () {
          return (this || _global).progressElement.parentNode ? document.documentElement.removeChild((this || _global).progressElement) : void 0;
        }, e.prototype.startTrickling = function () {
          return null != (this || _global).trickleInterval ? (this || _global).trickleInterval : (this || _global).trickleInterval = setInterval((this || _global).trickle, r);
        }, e.prototype.stopTrickling = function () {
          return clearInterval((this || _global).trickleInterval), (this || _global).trickleInterval = null;
        }, e.prototype.trickle = function () {
          return this.setValue((this || _global).value + Math.random() / 100);
        }, e.prototype.refresh = function () {
          return requestAnimationFrame(function (t) {
            return function () {
              return t.progressElement.style.width = 10 + 90 * t.value + "%";
            };
          }(this || _global));
        }, e.prototype.createStylesheetElement = function () {
          var t;
          return t = document.createElement("style"), t.type = "text/css", t.textContent = (this || _global).constructor.defaultCSS, t;
        }, e.prototype.createProgressElement = function () {
          var t;
          return t = document.createElement("div"), t.className = "turbolinks-progress-bar", t;
        }, e;
      }();
    }.call(this || _global), function () {
      var t = function (t, e) {
        return function () {
          return t.apply(e, arguments);
        };
      };

      e.BrowserAdapter = function () {
        function r(r) {
          (this || _global).controller = r, (this || _global).showProgressBar = t((this || _global).showProgressBar, this || _global), (this || _global).progressBar = new e.ProgressBar();
        }

        var n, o, i;
        return i = e.HttpRequest, n = i.NETWORK_FAILURE, o = i.TIMEOUT_FAILURE, r.prototype.visitProposedToLocationWithAction = function (t, e) {
          return (this || _global).controller.startVisitToLocationWithAction(t, e);
        }, r.prototype.visitStarted = function (t) {
          return t.issueRequest(), t.changeHistory(), t.loadCachedSnapshot();
        }, r.prototype.visitRequestStarted = function (t) {
          return (this || _global).progressBar.setValue(0), t.hasCachedSnapshot() || "restore" !== t.action ? this.showProgressBarAfterDelay() : this.showProgressBar();
        }, r.prototype.visitRequestProgressed = function (t) {
          return (this || _global).progressBar.setValue(t.progress);
        }, r.prototype.visitRequestCompleted = function (t) {
          return t.loadResponse();
        }, r.prototype.visitRequestFailedWithStatusCode = function (t, e) {
          switch (e) {
            case n:
            case o:
              return this.reload();

            default:
              return t.loadResponse();
          }
        }, r.prototype.visitRequestFinished = function (t) {
          return this.hideProgressBar();
        }, r.prototype.visitCompleted = function (t) {
          return t.followRedirect();
        }, r.prototype.pageInvalidated = function () {
          return this.reload();
        }, r.prototype.showProgressBarAfterDelay = function () {
          return (this || _global).progressBarTimeout = setTimeout((this || _global).showProgressBar, (this || _global).controller.progressBarDelay);
        }, r.prototype.showProgressBar = function () {
          return (this || _global).progressBar.show();
        }, r.prototype.hideProgressBar = function () {
          return (this || _global).progressBar.hide(), clearTimeout((this || _global).progressBarTimeout);
        }, r.prototype.reload = function () {
          return window.location.reload();
        }, r;
      }();
    }.call(this || _global), function () {
      var t = function (t, e) {
        return function () {
          return t.apply(e, arguments);
        };
      };

      e.History = function () {
        function r(e) {
          (this || _global).delegate = e, (this || _global).onPageLoad = t((this || _global).onPageLoad, this || _global), (this || _global).onPopState = t((this || _global).onPopState, this || _global);
        }

        return r.prototype.start = function () {
          return (this || _global).started ? void 0 : (addEventListener("popstate", (this || _global).onPopState, !1), addEventListener("load", (this || _global).onPageLoad, !1), (this || _global).started = !0);
        }, r.prototype.stop = function () {
          return (this || _global).started ? (removeEventListener("popstate", (this || _global).onPopState, !1), removeEventListener("load", (this || _global).onPageLoad, !1), (this || _global).started = !1) : void 0;
        }, r.prototype.push = function (t, r) {
          return t = e.Location.wrap(t), this.update("push", t, r);
        }, r.prototype.replace = function (t, r) {
          return t = e.Location.wrap(t), this.update("replace", t, r);
        }, r.prototype.onPopState = function (t) {
          var r, n, o, i;
          return this.shouldHandlePopState() && (i = null != (n = t.state) ? n.turbolinks : void 0) ? (r = e.Location.wrap(window.location), o = i.restorationIdentifier, (this || _global).delegate.historyPoppedToLocationWithRestorationIdentifier(r, o)) : void 0;
        }, r.prototype.onPageLoad = function (t) {
          return e.defer(function (t) {
            return function () {
              return t.pageLoaded = !0;
            };
          }(this || _global));
        }, r.prototype.shouldHandlePopState = function () {
          return this.pageIsLoaded();
        }, r.prototype.pageIsLoaded = function () {
          return (this || _global).pageLoaded || "complete" === document.readyState;
        }, r.prototype.update = function (t, e, r) {
          var n;
          return n = {
            turbolinks: {
              restorationIdentifier: r
            }
          }, history[t + "State"](n, null, e);
        }, r;
      }();
    }.call(this || _global), function () {
      e.HeadDetails = function () {
        function t(t) {
          var e, r, n, s, a, u;

          for ((this || _global).elements = {}, n = 0, a = t.length; a > n; n++) u = t[n], u.nodeType === Node.ELEMENT_NODE && (s = u.outerHTML, r = null != (e = (this || _global).elements)[s] ? e[s] : e[s] = {
            type: i(u),
            tracked: o(u),
            elements: []
          }, r.elements.push(u));
        }

        var e, r, n, o, i;
        return t.fromHeadElement = function (t) {
          var e;
          return new (this || _global)(null != (e = null != t ? t.childNodes : void 0) ? e : []);
        }, t.prototype.hasElementWithKey = function (t) {
          return t in (this || _global).elements;
        }, t.prototype.getTrackedElementSignature = function () {
          var t, e;
          return function () {
            var r, n;
            r = (this || _global).elements, n = [];

            for (t in r) e = r[t].tracked, e && n.push(t);

            return n;
          }.call(this || _global).join("");
        }, t.prototype.getScriptElementsNotInDetails = function (t) {
          return this.getElementsMatchingTypeNotInDetails("script", t);
        }, t.prototype.getStylesheetElementsNotInDetails = function (t) {
          return this.getElementsMatchingTypeNotInDetails("stylesheet", t);
        }, t.prototype.getElementsMatchingTypeNotInDetails = function (t, e) {
          var r, n, o, i, s, a;
          o = (this || _global).elements, s = [];

          for (n in o) i = o[n], a = i.type, r = i.elements, a !== t || e.hasElementWithKey(n) || s.push(r[0]);

          return s;
        }, t.prototype.getProvisionalElements = function () {
          var t, e, r, n, o, i, s;
          r = [], n = (this || _global).elements;

          for (e in n) o = n[e], s = o.type, i = o.tracked, t = o.elements, null != s || i ? t.length > 1 && r.push.apply(r, t.slice(1)) : r.push.apply(r, t);

          return r;
        }, t.prototype.getMetaValue = function (t) {
          var e;
          return null != (e = this.findMetaElementByName(t)) ? e.getAttribute("content") : void 0;
        }, t.prototype.findMetaElementByName = function (t) {
          var r, n, o, i;
          r = void 0, i = (this || _global).elements;

          for (o in i) n = i[o].elements, e(n[0], t) && (r = n[0]);

          return r;
        }, i = function (t) {
          return r(t) ? "script" : n(t) ? "stylesheet" : void 0;
        }, o = function (t) {
          return "reload" === t.getAttribute("data-turbolinks-track");
        }, r = function (t) {
          var e;
          return e = t.tagName.toLowerCase(), "script" === e;
        }, n = function (t) {
          var e;
          return e = t.tagName.toLowerCase(), "style" === e || "link" === e && "stylesheet" === t.getAttribute("rel");
        }, e = function (t, e) {
          var r;
          return r = t.tagName.toLowerCase(), "meta" === r && t.getAttribute("name") === e;
        }, t;
      }();
    }.call(this || _global), function () {
      e.Snapshot = function () {
        function t(t, e) {
          (this || _global).headDetails = t, (this || _global).bodyElement = e;
        }

        return t.wrap = function (t) {
          return t instanceof (this || _global) ? t : "string" == typeof t ? this.fromHTMLString(t) : this.fromHTMLElement(t);
        }, t.fromHTMLString = function (t) {
          var e;
          return e = document.createElement("html"), e.innerHTML = t, this.fromHTMLElement(e);
        }, t.fromHTMLElement = function (t) {
          var r, n, o, i;
          return o = t.querySelector("head"), r = null != (i = t.querySelector("body")) ? i : document.createElement("body"), n = e.HeadDetails.fromHeadElement(o), new (this || _global)(n, r);
        }, t.prototype.clone = function () {
          return new (this || _global).constructor((this || _global).headDetails, (this || _global).bodyElement.cloneNode(!0));
        }, t.prototype.getRootLocation = function () {
          var t, r;
          return r = null != (t = this.getSetting("root")) ? t : "/", new e.Location(r);
        }, t.prototype.getCacheControlValue = function () {
          return this.getSetting("cache-control");
        }, t.prototype.getElementForAnchor = function (t) {
          try {
            return (this || _global).bodyElement.querySelector("[id='" + t + "'], a[name='" + t + "']");
          } catch (e) {}
        }, t.prototype.getPermanentElements = function () {
          return (this || _global).bodyElement.querySelectorAll("[id][data-turbolinks-permanent]");
        }, t.prototype.getPermanentElementById = function (t) {
          return (this || _global).bodyElement.querySelector("#" + t + "[data-turbolinks-permanent]");
        }, t.prototype.getPermanentElementsPresentInSnapshot = function (t) {
          var e, r, n, o, i;

          for (o = this.getPermanentElements(), i = [], r = 0, n = o.length; n > r; r++) e = o[r], t.getPermanentElementById(e.id) && i.push(e);

          return i;
        }, t.prototype.findFirstAutofocusableElement = function () {
          return (this || _global).bodyElement.querySelector("[autofocus]");
        }, t.prototype.hasAnchor = function (t) {
          return null != this.getElementForAnchor(t);
        }, t.prototype.isPreviewable = function () {
          return "no-preview" !== this.getCacheControlValue();
        }, t.prototype.isCacheable = function () {
          return "no-cache" !== this.getCacheControlValue();
        }, t.prototype.isVisitable = function () {
          return "reload" !== this.getSetting("visit-control");
        }, t.prototype.getSetting = function (t) {
          return (this || _global).headDetails.getMetaValue("turbolinks-" + t);
        }, t;
      }();
    }.call(this || _global), function () {
      var t = [].slice;

      e.Renderer = function () {
        function e() {}

        var r;
        return e.render = function () {
          var e, r, n, o;
          return n = arguments[0], r = arguments[1], e = 3 <= arguments.length ? t.call(arguments, 2) : [], o = function (t, e, r) {
            r.prototype = t.prototype;
            var n = new r(),
                o = t.apply(n, e);
            return Object(o) === o ? o : n;
          }(this || _global, e, function () {}), o.delegate = n, o.render(r), o;
        }, e.prototype.renderView = function (t) {
          return (this || _global).delegate.viewWillRender((this || _global).newBody), t(), (this || _global).delegate.viewRendered((this || _global).newBody);
        }, e.prototype.invalidateView = function () {
          return (this || _global).delegate.viewInvalidated();
        }, e.prototype.createScriptElement = function (t) {
          var e;
          return "false" === t.getAttribute("data-turbolinks-eval") ? t : (e = document.createElement("script"), e.textContent = t.textContent, e.async = !1, r(e, t), e);
        }, r = function (t, e) {
          var r, n, o, i, s, a, u;

          for (i = e.attributes, a = [], r = 0, n = i.length; n > r; r++) s = i[r], o = s.name, u = s.value, a.push(t.setAttribute(o, u));

          return a;
        }, e;
      }();
    }.call(this || _global), function () {
      var t,
          r,
          n = function (t, e) {
        function r() {
          (this || _global).constructor = t;
        }

        for (var n in e) o.call(e, n) && (t[n] = e[n]);

        return r.prototype = e.prototype, t.prototype = new r(), t.__super__ = e.prototype, t;
      },
          o = {}.hasOwnProperty;

      e.SnapshotRenderer = function (e) {
        function o(t, e, r) {
          (this || _global).currentSnapshot = t, (this || _global).newSnapshot = e, (this || _global).isPreview = r, (this || _global).currentHeadDetails = (this || _global).currentSnapshot.headDetails, (this || _global).newHeadDetails = (this || _global).newSnapshot.headDetails, (this || _global).currentBody = (this || _global).currentSnapshot.bodyElement, (this || _global).newBody = (this || _global).newSnapshot.bodyElement;
        }

        return n(o, e), o.prototype.render = function (t) {
          return this.shouldRender() ? (this.mergeHead(), this.renderView(function (e) {
            return function () {
              return e.replaceBody(), e.isPreview || e.focusFirstAutofocusableElement(), t();
            };
          }(this || _global))) : this.invalidateView();
        }, o.prototype.mergeHead = function () {
          return this.copyNewHeadStylesheetElements(), this.copyNewHeadScriptElements(), this.removeCurrentHeadProvisionalElements(), this.copyNewHeadProvisionalElements();
        }, o.prototype.replaceBody = function () {
          var t;
          return t = this.relocateCurrentBodyPermanentElements(), this.activateNewBodyScriptElements(), this.assignNewBody(), this.replacePlaceholderElementsWithClonedPermanentElements(t);
        }, o.prototype.shouldRender = function () {
          return (this || _global).newSnapshot.isVisitable() && this.trackedElementsAreIdentical();
        }, o.prototype.trackedElementsAreIdentical = function () {
          return (this || _global).currentHeadDetails.getTrackedElementSignature() === (this || _global).newHeadDetails.getTrackedElementSignature();
        }, o.prototype.copyNewHeadStylesheetElements = function () {
          var t, e, r, n, o;

          for (n = this.getNewHeadStylesheetElements(), o = [], e = 0, r = n.length; r > e; e++) t = n[e], o.push(document.head.appendChild(t));

          return o;
        }, o.prototype.copyNewHeadScriptElements = function () {
          var t, e, r, n, o;

          for (n = this.getNewHeadScriptElements(), o = [], e = 0, r = n.length; r > e; e++) t = n[e], o.push(document.head.appendChild(this.createScriptElement(t)));

          return o;
        }, o.prototype.removeCurrentHeadProvisionalElements = function () {
          var t, e, r, n, o;

          for (n = this.getCurrentHeadProvisionalElements(), o = [], e = 0, r = n.length; r > e; e++) t = n[e], o.push(document.head.removeChild(t));

          return o;
        }, o.prototype.copyNewHeadProvisionalElements = function () {
          var t, e, r, n, o;

          for (n = this.getNewHeadProvisionalElements(), o = [], e = 0, r = n.length; r > e; e++) t = n[e], o.push(document.head.appendChild(t));

          return o;
        }, o.prototype.relocateCurrentBodyPermanentElements = function () {
          var e, n, o, i, s, a, u;

          for (a = this.getCurrentBodyPermanentElements(), u = [], e = 0, n = a.length; n > e; e++) i = a[e], s = t(i), o = (this || _global).newSnapshot.getPermanentElementById(i.id), r(i, s.element), r(o, i), u.push(s);

          return u;
        }, o.prototype.replacePlaceholderElementsWithClonedPermanentElements = function (t) {
          var e, n, o, i, s, a, u;

          for (u = [], o = 0, i = t.length; i > o; o++) a = t[o], n = a.element, s = a.permanentElement, e = s.cloneNode(!0), u.push(r(n, e));

          return u;
        }, o.prototype.activateNewBodyScriptElements = function () {
          var t, e, n, o, i, s;

          for (i = this.getNewBodyScriptElements(), s = [], e = 0, o = i.length; o > e; e++) n = i[e], t = this.createScriptElement(n), s.push(r(n, t));

          return s;
        }, o.prototype.assignNewBody = function () {
          return document.body = (this || _global).newBody;
        }, o.prototype.focusFirstAutofocusableElement = function () {
          var t;
          return null != (t = (this || _global).newSnapshot.findFirstAutofocusableElement()) ? t.focus() : void 0;
        }, o.prototype.getNewHeadStylesheetElements = function () {
          return (this || _global).newHeadDetails.getStylesheetElementsNotInDetails((this || _global).currentHeadDetails);
        }, o.prototype.getNewHeadScriptElements = function () {
          return (this || _global).newHeadDetails.getScriptElementsNotInDetails((this || _global).currentHeadDetails);
        }, o.prototype.getCurrentHeadProvisionalElements = function () {
          return (this || _global).currentHeadDetails.getProvisionalElements();
        }, o.prototype.getNewHeadProvisionalElements = function () {
          return (this || _global).newHeadDetails.getProvisionalElements();
        }, o.prototype.getCurrentBodyPermanentElements = function () {
          return (this || _global).currentSnapshot.getPermanentElementsPresentInSnapshot((this || _global).newSnapshot);
        }, o.prototype.getNewBodyScriptElements = function () {
          return (this || _global).newBody.querySelectorAll("script");
        }, o;
      }(e.Renderer), t = function (t) {
        var e;
        return e = document.createElement("meta"), e.setAttribute("name", "turbolinks-permanent-placeholder"), e.setAttribute("content", t.id), {
          element: e,
          permanentElement: t
        };
      }, r = function (t, e) {
        var r;
        return (r = t.parentNode) ? r.replaceChild(e, t) : void 0;
      };
    }.call(this || _global), function () {
      var t = function (t, e) {
        function n() {
          (this || _global).constructor = t;
        }

        for (var o in e) r.call(e, o) && (t[o] = e[o]);

        return n.prototype = e.prototype, t.prototype = new n(), t.__super__ = e.prototype, t;
      },
          r = {}.hasOwnProperty;

      e.ErrorRenderer = function (e) {
        function r(t) {
          var e;
          e = document.createElement("html"), e.innerHTML = t, (this || _global).newHead = e.querySelector("head"), (this || _global).newBody = e.querySelector("body");
        }

        return t(r, e), r.prototype.render = function (t) {
          return this.renderView(function (e) {
            return function () {
              return e.replaceHeadAndBody(), e.activateBodyScriptElements(), t();
            };
          }(this || _global));
        }, r.prototype.replaceHeadAndBody = function () {
          var t, e;
          return e = document.head, t = document.body, e.parentNode.replaceChild((this || _global).newHead, e), t.parentNode.replaceChild((this || _global).newBody, t);
        }, r.prototype.activateBodyScriptElements = function () {
          var t, e, r, n, o, i;

          for (n = this.getScriptElements(), i = [], e = 0, r = n.length; r > e; e++) o = n[e], t = this.createScriptElement(o), i.push(o.parentNode.replaceChild(t, o));

          return i;
        }, r.prototype.getScriptElements = function () {
          return document.documentElement.querySelectorAll("script");
        }, r;
      }(e.Renderer);
    }.call(this || _global), function () {
      e.View = function () {
        function t(t) {
          (this || _global).delegate = t, (this || _global).htmlElement = document.documentElement;
        }

        return t.prototype.getRootLocation = function () {
          return this.getSnapshot().getRootLocation();
        }, t.prototype.getElementForAnchor = function (t) {
          return this.getSnapshot().getElementForAnchor(t);
        }, t.prototype.getSnapshot = function () {
          return e.Snapshot.fromHTMLElement((this || _global).htmlElement);
        }, t.prototype.render = function (t, e) {
          var r, n, o;
          return o = t.snapshot, r = t.error, n = t.isPreview, this.markAsPreview(n), null != o ? this.renderSnapshot(o, n, e) : this.renderError(r, e);
        }, t.prototype.markAsPreview = function (t) {
          return t ? (this || _global).htmlElement.setAttribute("data-turbolinks-preview", "") : (this || _global).htmlElement.removeAttribute("data-turbolinks-preview");
        }, t.prototype.renderSnapshot = function (t, r, n) {
          return e.SnapshotRenderer.render((this || _global).delegate, n, this.getSnapshot(), e.Snapshot.wrap(t), r);
        }, t.prototype.renderError = function (t, r) {
          return e.ErrorRenderer.render((this || _global).delegate, r, t);
        }, t;
      }();
    }.call(this || _global), function () {
      var t = function (t, e) {
        return function () {
          return t.apply(e, arguments);
        };
      };

      e.ScrollManager = function () {
        function r(r) {
          (this || _global).delegate = r, (this || _global).onScroll = t((this || _global).onScroll, this || _global), (this || _global).onScroll = e.throttle((this || _global).onScroll);
        }

        return r.prototype.start = function () {
          return (this || _global).started ? void 0 : (addEventListener("scroll", (this || _global).onScroll, !1), this.onScroll(), (this || _global).started = !0);
        }, r.prototype.stop = function () {
          return (this || _global).started ? (removeEventListener("scroll", (this || _global).onScroll, !1), (this || _global).started = !1) : void 0;
        }, r.prototype.scrollToElement = function (t) {
          return t.scrollIntoView();
        }, r.prototype.scrollToPosition = function (t) {
          var e, r;
          return e = t.x, r = t.y, window.scrollTo(e, r);
        }, r.prototype.onScroll = function (t) {
          return this.updatePosition({
            x: window.pageXOffset,
            y: window.pageYOffset
          });
        }, r.prototype.updatePosition = function (t) {
          var e;
          return (this || _global).position = t, null != (e = (this || _global).delegate) ? e.scrollPositionChanged((this || _global).position) : void 0;
        }, r;
      }();
    }.call(this || _global), function () {
      e.SnapshotCache = function () {
        function t(t) {
          (this || _global).size = t, (this || _global).keys = [], (this || _global).snapshots = {};
        }

        var r;
        return t.prototype.has = function (t) {
          var e;
          return e = r(t), e in (this || _global).snapshots;
        }, t.prototype.get = function (t) {
          var e;
          if (this.has(t)) return e = this.read(t), this.touch(t), e;
        }, t.prototype.put = function (t, e) {
          return this.write(t, e), this.touch(t), e;
        }, t.prototype.read = function (t) {
          var e;
          return e = r(t), (this || _global).snapshots[e];
        }, t.prototype.write = function (t, e) {
          var n;
          return n = r(t), (this || _global).snapshots[n] = e;
        }, t.prototype.touch = function (t) {
          var e, n;
          return n = r(t), e = (this || _global).keys.indexOf(n), e > -1 && (this || _global).keys.splice(e, 1), (this || _global).keys.unshift(n), this.trim();
        }, t.prototype.trim = function () {
          var t, e, r, n, o;

          for (n = (this || _global).keys.splice((this || _global).size), o = [], t = 0, r = n.length; r > t; t++) e = n[t], o.push(delete (this || _global).snapshots[e]);

          return o;
        }, r = function (t) {
          return e.Location.wrap(t).toCacheKey();
        }, t;
      }();
    }.call(this || _global), function () {
      var t = function (t, e) {
        return function () {
          return t.apply(e, arguments);
        };
      };

      e.Visit = function () {
        function r(r, n, o) {
          (this || _global).controller = r, (this || _global).action = o, (this || _global).performScroll = t((this || _global).performScroll, this || _global), (this || _global).identifier = e.uuid(), (this || _global).location = e.Location.wrap(n), (this || _global).adapter = (this || _global).controller.adapter, (this || _global).state = "initialized", (this || _global).timingMetrics = {};
        }

        var n;
        return r.prototype.start = function () {
          return "initialized" === (this || _global).state ? (this.recordTimingMetric("visitStart"), (this || _global).state = "started", (this || _global).adapter.visitStarted(this || _global)) : void 0;
        }, r.prototype.cancel = function () {
          var t;
          return "started" === (this || _global).state ? (null != (t = (this || _global).request) && t.cancel(), this.cancelRender(), (this || _global).state = "canceled") : void 0;
        }, r.prototype.complete = function () {
          var t;
          return "started" === (this || _global).state ? (this.recordTimingMetric("visitEnd"), (this || _global).state = "completed", "function" == typeof (t = (this || _global).adapter).visitCompleted && t.visitCompleted(this || _global), (this || _global).controller.visitCompleted(this || _global)) : void 0;
        }, r.prototype.fail = function () {
          var t;
          return "started" === (this || _global).state ? ((this || _global).state = "failed", "function" == typeof (t = (this || _global).adapter).visitFailed ? t.visitFailed(this || _global) : void 0) : void 0;
        }, r.prototype.changeHistory = function () {
          var t, e;
          return (this || _global).historyChanged ? void 0 : (t = (this || _global).location.isEqualTo((this || _global).referrer) ? "replace" : (this || _global).action, e = n(t), (this || _global).controller[e]((this || _global).location, (this || _global).restorationIdentifier), (this || _global).historyChanged = !0);
        }, r.prototype.issueRequest = function () {
          return this.shouldIssueRequest() && null == (this || _global).request ? ((this || _global).progress = 0, (this || _global).request = new e.HttpRequest(this || _global, (this || _global).location, (this || _global).referrer), (this || _global).request.send()) : void 0;
        }, r.prototype.getCachedSnapshot = function () {
          var t;
          return !(t = (this || _global).controller.getCachedSnapshotForLocation((this || _global).location)) || null != (this || _global).location.anchor && !t.hasAnchor((this || _global).location.anchor) || "restore" !== (this || _global).action && !t.isPreviewable() ? void 0 : t;
        }, r.prototype.hasCachedSnapshot = function () {
          return null != this.getCachedSnapshot();
        }, r.prototype.loadCachedSnapshot = function () {
          var t, e;
          return (e = this.getCachedSnapshot()) ? (t = this.shouldIssueRequest(), this.render(function () {
            var r;
            return this.cacheSnapshot(), (this || _global).controller.render({
              snapshot: e,
              isPreview: t
            }, (this || _global).performScroll), "function" == typeof (r = (this || _global).adapter).visitRendered && r.visitRendered(this || _global), t ? void 0 : this.complete();
          })) : void 0;
        }, r.prototype.loadResponse = function () {
          return null != (this || _global).response ? this.render(function () {
            var t, e;
            return this.cacheSnapshot(), (this || _global).request.failed ? ((this || _global).controller.render({
              error: (this || _global).response
            }, (this || _global).performScroll), "function" == typeof (t = (this || _global).adapter).visitRendered && t.visitRendered(this || _global), this.fail()) : ((this || _global).controller.render({
              snapshot: (this || _global).response
            }, (this || _global).performScroll), "function" == typeof (e = (this || _global).adapter).visitRendered && e.visitRendered(this || _global), this.complete());
          }) : void 0;
        }, r.prototype.followRedirect = function () {
          return (this || _global).redirectedToLocation && !(this || _global).followedRedirect ? ((this || _global).location = (this || _global).redirectedToLocation, (this || _global).controller.replaceHistoryWithLocationAndRestorationIdentifier((this || _global).redirectedToLocation, (this || _global).restorationIdentifier), (this || _global).followedRedirect = !0) : void 0;
        }, r.prototype.requestStarted = function () {
          var t;
          return this.recordTimingMetric("requestStart"), "function" == typeof (t = (this || _global).adapter).visitRequestStarted ? t.visitRequestStarted(this || _global) : void 0;
        }, r.prototype.requestProgressed = function (t) {
          var e;
          return (this || _global).progress = t, "function" == typeof (e = (this || _global).adapter).visitRequestProgressed ? e.visitRequestProgressed(this || _global) : void 0;
        }, r.prototype.requestCompletedWithResponse = function (t, r) {
          return (this || _global).response = t, null != r && ((this || _global).redirectedToLocation = e.Location.wrap(r)), (this || _global).adapter.visitRequestCompleted(this || _global);
        }, r.prototype.requestFailedWithStatusCode = function (t, e) {
          return (this || _global).response = e, (this || _global).adapter.visitRequestFailedWithStatusCode(this || _global, t);
        }, r.prototype.requestFinished = function () {
          var t;
          return this.recordTimingMetric("requestEnd"), "function" == typeof (t = (this || _global).adapter).visitRequestFinished ? t.visitRequestFinished(this || _global) : void 0;
        }, r.prototype.performScroll = function () {
          return (this || _global).scrolled ? void 0 : ("restore" === (this || _global).action ? this.scrollToRestoredPosition() || this.scrollToTop() : this.scrollToAnchor() || this.scrollToTop(), (this || _global).scrolled = !0);
        }, r.prototype.scrollToRestoredPosition = function () {
          var t, e;
          return t = null != (e = (this || _global).restorationData) ? e.scrollPosition : void 0, null != t ? ((this || _global).controller.scrollToPosition(t), !0) : void 0;
        }, r.prototype.scrollToAnchor = function () {
          return null != (this || _global).location.anchor ? ((this || _global).controller.scrollToAnchor((this || _global).location.anchor), !0) : void 0;
        }, r.prototype.scrollToTop = function () {
          return (this || _global).controller.scrollToPosition({
            x: 0,
            y: 0
          });
        }, r.prototype.recordTimingMetric = function (t) {
          var e;
          return null != (e = (this || _global).timingMetrics)[t] ? e[t] : e[t] = new Date().getTime();
        }, r.prototype.getTimingMetrics = function () {
          return e.copyObject((this || _global).timingMetrics);
        }, n = function (t) {
          switch (t) {
            case "replace":
              return "replaceHistoryWithLocationAndRestorationIdentifier";

            case "advance":
            case "restore":
              return "pushHistoryWithLocationAndRestorationIdentifier";
          }
        }, r.prototype.shouldIssueRequest = function () {
          return "restore" === (this || _global).action ? !this.hasCachedSnapshot() : !0;
        }, r.prototype.cacheSnapshot = function () {
          return (this || _global).snapshotCached ? void 0 : ((this || _global).controller.cacheSnapshot(), (this || _global).snapshotCached = !0);
        }, r.prototype.render = function (t) {
          return this.cancelRender(), (this || _global).frame = requestAnimationFrame(function (e) {
            return function () {
              return e.frame = null, t.call(e);
            };
          }(this || _global));
        }, r.prototype.cancelRender = function () {
          return (this || _global).frame ? cancelAnimationFrame((this || _global).frame) : void 0;
        }, r;
      }();
    }.call(this || _global), function () {
      var t = function (t, e) {
        return function () {
          return t.apply(e, arguments);
        };
      };

      e.Controller = function () {
        function r() {
          (this || _global).clickBubbled = t((this || _global).clickBubbled, this || _global), (this || _global).clickCaptured = t((this || _global).clickCaptured, this || _global), (this || _global).pageLoaded = t((this || _global).pageLoaded, this || _global), (this || _global).history = new e.History(this || _global), (this || _global).view = new e.View(this || _global), (this || _global).scrollManager = new e.ScrollManager(this || _global), (this || _global).restorationData = {}, this.clearCache(), this.setProgressBarDelay(500);
        }

        return r.prototype.start = function () {
          return e.supported && !(this || _global).started ? (addEventListener("click", (this || _global).clickCaptured, !0), addEventListener("DOMContentLoaded", (this || _global).pageLoaded, !1), (this || _global).scrollManager.start(), this.startHistory(), (this || _global).started = !0, (this || _global).enabled = !0) : void 0;
        }, r.prototype.disable = function () {
          return (this || _global).enabled = !1;
        }, r.prototype.stop = function () {
          return (this || _global).started ? (removeEventListener("click", (this || _global).clickCaptured, !0), removeEventListener("DOMContentLoaded", (this || _global).pageLoaded, !1), (this || _global).scrollManager.stop(), this.stopHistory(), (this || _global).started = !1) : void 0;
        }, r.prototype.clearCache = function () {
          return (this || _global).cache = new e.SnapshotCache(10);
        }, r.prototype.visit = function (t, r) {
          var n, o;
          return null == r && (r = {}), t = e.Location.wrap(t), this.applicationAllowsVisitingLocation(t) ? this.locationIsVisitable(t) ? (n = null != (o = r.action) ? o : "advance", (this || _global).adapter.visitProposedToLocationWithAction(t, n)) : window.location = t : void 0;
        }, r.prototype.startVisitToLocationWithAction = function (t, r, n) {
          var o;
          return e.supported ? (o = this.getRestorationDataForIdentifier(n), this.startVisit(t, r, {
            restorationData: o
          })) : window.location = t;
        }, r.prototype.setProgressBarDelay = function (t) {
          return (this || _global).progressBarDelay = t;
        }, r.prototype.startHistory = function () {
          return (this || _global).location = e.Location.wrap(window.location), (this || _global).restorationIdentifier = e.uuid(), (this || _global).history.start(), (this || _global).history.replace((this || _global).location, (this || _global).restorationIdentifier);
        }, r.prototype.stopHistory = function () {
          return (this || _global).history.stop();
        }, r.prototype.pushHistoryWithLocationAndRestorationIdentifier = function (t, r) {
          return (this || _global).restorationIdentifier = r, (this || _global).location = e.Location.wrap(t), (this || _global).history.push((this || _global).location, (this || _global).restorationIdentifier);
        }, r.prototype.replaceHistoryWithLocationAndRestorationIdentifier = function (t, r) {
          return (this || _global).restorationIdentifier = r, (this || _global).location = e.Location.wrap(t), (this || _global).history.replace((this || _global).location, (this || _global).restorationIdentifier);
        }, r.prototype.historyPoppedToLocationWithRestorationIdentifier = function (t, r) {
          var n;
          return (this || _global).restorationIdentifier = r, (this || _global).enabled ? (n = this.getRestorationDataForIdentifier((this || _global).restorationIdentifier), this.startVisit(t, "restore", {
            restorationIdentifier: (this || _global).restorationIdentifier,
            restorationData: n,
            historyChanged: !0
          }), (this || _global).location = e.Location.wrap(t)) : (this || _global).adapter.pageInvalidated();
        }, r.prototype.getCachedSnapshotForLocation = function (t) {
          var e;
          return null != (e = (this || _global).cache.get(t)) ? e.clone() : void 0;
        }, r.prototype.shouldCacheSnapshot = function () {
          return (this || _global).view.getSnapshot().isCacheable();
        }, r.prototype.cacheSnapshot = function () {
          var t, r;
          return this.shouldCacheSnapshot() ? (this.notifyApplicationBeforeCachingSnapshot(), r = (this || _global).view.getSnapshot(), t = (this || _global).lastRenderedLocation, e.defer(function (e) {
            return function () {
              return e.cache.put(t, r.clone());
            };
          }(this || _global))) : void 0;
        }, r.prototype.scrollToAnchor = function (t) {
          var e;
          return (e = (this || _global).view.getElementForAnchor(t)) ? this.scrollToElement(e) : this.scrollToPosition({
            x: 0,
            y: 0
          });
        }, r.prototype.scrollToElement = function (t) {
          return (this || _global).scrollManager.scrollToElement(t);
        }, r.prototype.scrollToPosition = function (t) {
          return (this || _global).scrollManager.scrollToPosition(t);
        }, r.prototype.scrollPositionChanged = function (t) {
          var e;
          return e = this.getCurrentRestorationData(), e.scrollPosition = t;
        }, r.prototype.render = function (t, e) {
          return (this || _global).view.render(t, e);
        }, r.prototype.viewInvalidated = function () {
          return (this || _global).adapter.pageInvalidated();
        }, r.prototype.viewWillRender = function (t) {
          return this.notifyApplicationBeforeRender(t);
        }, r.prototype.viewRendered = function () {
          return (this || _global).lastRenderedLocation = (this || _global).currentVisit.location, this.notifyApplicationAfterRender();
        }, r.prototype.pageLoaded = function () {
          return (this || _global).lastRenderedLocation = (this || _global).location, this.notifyApplicationAfterPageLoad();
        }, r.prototype.clickCaptured = function () {
          return removeEventListener("click", (this || _global).clickBubbled, !1), addEventListener("click", (this || _global).clickBubbled, !1);
        }, r.prototype.clickBubbled = function (t) {
          var e, r, n;
          return (this || _global).enabled && this.clickEventIsSignificant(t) && (r = this.getVisitableLinkForNode(t.target)) && (n = this.getVisitableLocationForLink(r)) && this.applicationAllowsFollowingLinkToLocation(r, n) ? (t.preventDefault(), e = this.getActionForLink(r), this.visit(n, {
            action: e
          })) : void 0;
        }, r.prototype.applicationAllowsFollowingLinkToLocation = function (t, e) {
          var r;
          return r = this.notifyApplicationAfterClickingLinkToLocation(t, e), !r.defaultPrevented;
        }, r.prototype.applicationAllowsVisitingLocation = function (t) {
          var e;
          return e = this.notifyApplicationBeforeVisitingLocation(t), !e.defaultPrevented;
        }, r.prototype.notifyApplicationAfterClickingLinkToLocation = function (t, r) {
          return e.dispatch("turbolinks:click", {
            target: t,
            data: {
              url: r.absoluteURL
            },
            cancelable: !0
          });
        }, r.prototype.notifyApplicationBeforeVisitingLocation = function (t) {
          return e.dispatch("turbolinks:before-visit", {
            data: {
              url: t.absoluteURL
            },
            cancelable: !0
          });
        }, r.prototype.notifyApplicationAfterVisitingLocation = function (t) {
          return e.dispatch("turbolinks:visit", {
            data: {
              url: t.absoluteURL
            }
          });
        }, r.prototype.notifyApplicationBeforeCachingSnapshot = function () {
          return e.dispatch("turbolinks:before-cache");
        }, r.prototype.notifyApplicationBeforeRender = function (t) {
          return e.dispatch("turbolinks:before-render", {
            data: {
              newBody: t
            }
          });
        }, r.prototype.notifyApplicationAfterRender = function () {
          return e.dispatch("turbolinks:render");
        }, r.prototype.notifyApplicationAfterPageLoad = function (t) {
          return null == t && (t = {}), e.dispatch("turbolinks:load", {
            data: {
              url: (this || _global).location.absoluteURL,
              timing: t
            }
          });
        }, r.prototype.startVisit = function (t, e, r) {
          var n;
          return null != (n = (this || _global).currentVisit) && n.cancel(), (this || _global).currentVisit = this.createVisit(t, e, r), (this || _global).currentVisit.start(), this.notifyApplicationAfterVisitingLocation(t);
        }, r.prototype.createVisit = function (t, r, n) {
          var o, i, s, a, u;
          return i = null != n ? n : {}, a = i.restorationIdentifier, s = i.restorationData, o = i.historyChanged, u = new e.Visit(this || _global, t, r), u.restorationIdentifier = null != a ? a : e.uuid(), u.restorationData = e.copyObject(s), u.historyChanged = o, u.referrer = (this || _global).location, u;
        }, r.prototype.visitCompleted = function (t) {
          return this.notifyApplicationAfterPageLoad(t.getTimingMetrics());
        }, r.prototype.clickEventIsSignificant = function (t) {
          return !(t.defaultPrevented || t.target.isContentEditable || t.which > 1 || t.altKey || t.ctrlKey || t.metaKey || t.shiftKey);
        }, r.prototype.getVisitableLinkForNode = function (t) {
          return this.nodeIsVisitable(t) ? e.closest(t, "a[href]:not([target]):not([download])") : void 0;
        }, r.prototype.getVisitableLocationForLink = function (t) {
          var r;
          return r = new e.Location(t.getAttribute("href")), this.locationIsVisitable(r) ? r : void 0;
        }, r.prototype.getActionForLink = function (t) {
          var e;
          return null != (e = t.getAttribute("data-turbolinks-action")) ? e : "advance";
        }, r.prototype.nodeIsVisitable = function (t) {
          var r;
          return (r = e.closest(t, "[data-turbolinks]")) ? "false" !== r.getAttribute("data-turbolinks") : !0;
        }, r.prototype.locationIsVisitable = function (t) {
          return t.isPrefixedBy((this || _global).view.getRootLocation()) && t.isHTML();
        }, r.prototype.getCurrentRestorationData = function () {
          return this.getRestorationDataForIdentifier((this || _global).restorationIdentifier);
        }, r.prototype.getRestorationDataForIdentifier = function (t) {
          var e;
          return null != (e = (this || _global).restorationData)[t] ? e[t] : e[t] = {};
        }, r;
      }();
    }.call(this || _global), function () {
      !function () {
        var t, e;
        if ((t = e = document.currentScript) && !e.hasAttribute("data-turbolinks-suppress-warning")) for (; t = t.parentNode;) if (t === document.body) return console.warn("You are loading Turbolinks from a <script> element inside the <body> element. This is probably not what you meant to do!\n\nLoad your application\u2019s JavaScript bundle inside the <head> element instead. <script> elements in <body> are evaluated with each page change.\n\nFor more information, see: https://github.com/turbolinks/turbolinks#working-with-script-elements\n\n\u2014\u2014\nSuppress this warning by adding a `data-turbolinks-suppress-warning` attribute to: %s", e.outerHTML);
      }();
    }.call(this || _global), function () {
      var t, r, n;
      e.start = function () {
        return r() ? (null == e.controller && (e.controller = t()), e.controller.start()) : void 0;
      }, r = function () {
        return null == window.Turbolinks && (window.Turbolinks = e), n();
      }, t = function () {
        var t;
        return t = new e.Controller(), t.adapter = new e.BrowserAdapter(t), t;
      }, n = function () {
        return window.Turbolinks === e;
      }, n() && e.start();
    }.call(this || _global);
  }).call(this || _global), exports ? exports = e : false;
}).call(exports);
export default exports;